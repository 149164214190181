<template>
  <div class="divBox">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #searchSlot>
        <v-input
          label="姓名"
          v-model="searchParam.name"
          placeholder="请输入姓名"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.auditStatus == 0"
          text="审核"
          type="text"
          @click="handleAudit(scope.row)"
        />
        <v-button
          v-else-if="scope.row.auditStatus == 1"
          text="审核通过"
          type="text"
          disabled
        />
        <v-button
          v-else-if="scope.row.auditStatus == 2"
          text="审核不通过"
          type="text"
          disabled
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      title="审核"
      v-model="dialogFormVisible"
      width="50%"
      @confirm="saveEdit"
      sureTxt="保存"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="姓名">
          <v-input v-model="temp.name" disabled></v-input>
        </el-form-item>
        <el-form-item label="性别">
          <v-select disabled :options="sexOption" v-model="temp.sex" />
        </el-form-item>
        <el-form-item label="出生日期">
          <v-input v-model="temp.birthday" disabled></v-input>
        </el-form-item>
        <el-form-item label="服务特长">
          <v-input
            v-model="temp.serviceSpecialty"
            disabled
            type="textarea"
          ></v-input>
        </el-form-item>
        <el-form-item label="照片">
          <v-upload :limit="9" disabled :imgUrls="temp.photo" />
        </el-form-item>
        <el-form-item label="审核">
          <v-select
            :options="aduitOption"
            v-model="status"
            placeholder="请选择审核结果"
          />
        </el-form-item>
        <el-form-item label="备注" v-if="status == 2">
          <v-input
            v-model="auditRemarks"
            placeholder="请输入不通过原因"
            type="textarea"
          ></v-input>
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  nanxiSisterApplicationDetailsUrl,
  nanxiSisterApplicationListUrl,
  deleteNanxiSisterUrl,
  auditNanxiSisterUrl,
} from "./api.js";
export default {
  name: "sisterExamine",
  data() {
    return {
      auditRemarks: null,
      status: null,
      temp: {},
      dialogFormVisible: false,
      searchParam: {
        name: "",
      },
      aduitOption: [
        { value: 2, label: "不通过" },
        { value: 1, label: "通过" },
      ],
      sexOption: [
        { value: 1, label: "男" },
        { value: 2, label: "女" },
      ],
      tableUrl: nanxiSisterApplicationListUrl,
      headers: [
        {
          prop: "id",
          label: "编号",
        },
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "sex",
          label: "性别",
          formatter: (row, prop) => {
            if (row[prop] == 1) {
              return "男";
            } else {
              return "女";
            }
          },
        },
        {
          prop: "birthday",
          label: "出生日期",
        },
      ],
    };
  },
  methods: {
    saveEdit() {
      if (!this.status) {
        this.$message.error("请选择审核结果");
        return;
      }
      if (this.status == 2 && !this.auditRemarks) {
        this.$message.error("请输入不通过原因");
        return;
      }
      let params = {
        id: this.temp.id,
        status: this.status,
        auditRemarks: this.auditRemarks,
      };
      this.$axios
        .post(auditNanxiSisterUrl, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.code);
            this.$refs.list.search();
            this.dialogFormVisible = false;
          }
        });
    },
    handleAudit(item) {
      let params = {
        id: item.id,
      };
      this.$axios
        .get(nanxiSisterApplicationDetailsUrl, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.temp = res.data;
          }
        });
      this.dialogFormVisible = true;
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: item.id,
        };
        this.$axios
          .post(`${deleteNanxiSisterUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
